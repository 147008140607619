import { isReady } from 'app/services/jwtService/isReady';
import axios from 'axios';

function newAbortSignal(timeoutMs) {
	const abortController = new AbortController();
	setTimeout(() => abortController.abort(), timeoutMs || 0);
	return abortController.signal;
}

export const api = axios.create({
	baseURL: localStorage.getItem('baseUrl') || process.env.REACT_APP_API_URL
});

api.interceptors.request.use(
	async config => {
		await isReady.wait();

		if (config.method === 'get') {
			config.signal = newAbortSignal(10000);
		}

		if (String(config.headers.Authorization).toLowerCase() === 'basic') {
			config.headers.Authorization = 'Basic ZnN0ZmR1c2VyOmZzdGZkcHN3ZA==';
		} else {
			const accessToken = JSON.parse(sessionStorage.getItem('activeAccount'))?.access_token;
			if (accessToken) {
				config.headers.Authorization = accessToken;
			}
		}

		return config;
	},
	error => {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	response => {
		return response;
	},
	error => {
		if (error?.response?.status === 406) {
			throw error;
		}

		if (error?.response) {
			const requestHeaders = error.config?.headers;

			if (!error.config.url.includes('logger')) {
				sendApiErrorToBackend(error, requestHeaders);
			}

			return handleError(error?.response, requestHeaders?.Authorization);
		}

		throw error;
	}
);

// Error handling function
function handleError(response, token) {
	const { status, data } = response;

	if (status && status >= 400 && status <= 600) {
		const messages = data?.message || data?.error;

		if ((status === 401 || status === 426) && token) {
			localStorage.clear();
			sessionStorage.clear();
			window.location.reload();

			// store.dispatch(logoutUser());
		}

		if (typeof messages === 'string' || Array.isArray(messages)) {
			if (Array.isArray(messages)) {
				const [message] = messages;

				throw new Error(message);
			}

			throw new Error(messages);
		}

		throw Error(String(response));
	}

	if ('data' in response) return response;
}

async function sendApiErrorToBackend(error, requestHeaders) {
	const { status, data, config } = error.response;

	const errorPayload = {
		message: JSON.stringify(data?.message) || 'Unknown API Error',
		name: 'API Error',
		stack: error.stack,
		path: config.url,
		extra: JSON.stringify({
			method: config.method,
			timestamp: new Date().toISOString(),
			status,
			requestHeaders
		})
	};

	try {
		await api({
			method: 'POST',
			url: 'logger',
			data: JSON.stringify(errorPayload),
			headers: { 'Content-Type': 'application/json' }
		});
	} catch (err) {
		console.error('Failed to send API error to backend:', err);
	}
}
